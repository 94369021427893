
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import './App.css';
import Layout from './components/layout';
import Carousel from './components/carousel';
import ResponsiveContainer from './components/responsivecontainer';
import ModernText from './components/moderntext';

const TRACKING_ID = 'G-Q5H27MC8BH '; // Replace with your Google Analytics tracking ID

function App() {
  const customImages = [
    { src: '/Plan presencia.png', alt: 'Custom Image 1', text: 'Nuestro Plan presencia digital te ayudara a posicionarte en el mundo digital obteniendo beneficios inmediatos para tu negocio!' },
    { src: '/MarzoAbril.png', alt: 'Custom Image 2', text: 'Web site o landing-page + presencia en redes sociales y 8 publicaciones + 4 diseño de piezas de marketing digital!' },
    { src: '/Servicio.png', alt: 'Custom Image 3', text: 'Un servicio personalizado muchas veces es fundamental para tu negocio, no dudes en contactarnos por otro tipo de servicio que requieras' }
  ];

  useEffect(() => {
    // Initialize Google Analytics and track the page view
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: 'pageview', page: '/' });
  }, []);

  return (
    <Layout>
      <h1>Inicio</h1>
      <div className="App">
        <Carousel />
        <header className="App-header">
          <ModernText>
            <h3>¡Bienvenidos a Arete!</h3>
            <p>
              Nuestro nombre, "Arete", proviene de un antiguo concepto griego que representa la excelencia y la virtud, valores que nos esforzamos por reflejar en todo lo que hacemos.
              Nuestro equipo altamente calificado está aquí para ayudarte a crear un sitio web de vanguardia, gestionar tu presencia en redes sociales de forma efectiva, y aumentar tus ventas con estrategias de marketing digital innovadoras.
            </p>
          </ModernText>

          <ResponsiveContainer images={customImages} />
        </header>
      </div>
    </Layout>
  );
}

export default App;

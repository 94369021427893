import React from 'react';
import './flipresponsivecontainer.css'; // You will need to create this CSS file
import './moderntext'
import ModernText from './moderntext';

const FlipResponsiveContainer = ({ cards }) => {
  return (
    <div className="flip-responsive-container" >
      {cards.map((card, index) => (
        <div key={index} className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front" >
              <img className='img' style={{width:'100%',height:'100%'}} src={card.frontImageSrc} alt={card.alt} />
              <p className='fronttext' style={{fontSize:'10px'}}>{card.frontText}</p>
            </div>
            <div className="flip-card-back">
              <ModernText><p>{card.backText}</p></ModernText>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FlipResponsiveContainer;




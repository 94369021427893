import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import './carousel.css';

const Carousel = () => {
  const images = [
    { src: '/Web1.png', buttonText: 'Ver mas...', buttonLink: '/Second' },
    { src: '/redes (2).png', buttonText: 'Ver mas...', buttonLink: '/Second' },
    { src: '/mktd.png', buttonText: 'Ver mas...', buttonLink: '/Second' },
    { src: '/CS.png', buttonText: 'Ver mas...', buttonLink: '/Second' },
  ];
  const [currentImage, setCurrentImage] = useState(0);

  const nextImage = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prevImage) => (prevImage - 1 + images.length) % images.length);
  };

  useEffect(() => {
    // Auto transition every 5 seconds
    const intervalId = setInterval(nextImage, 4000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [currentImage]);

  

  return (
    <div className="carousel-container">
      <Button variant="contained" component={Link} to="/Second"  >Ver mas...</Button>
      
      {images.map((image, index) => (
        <img
          key={index}
          src={image.src}
          alt={`Slide ${index + 1}`}
          style={{
            display: index === currentImage ? 'block' : 'none',
            width: '50%', // Adjust the width as needed
            height: '100%', // Adjust the maximum height as needed
            margin:'5px'
          }}
        />
      ))}
      <div>
        <Button className="nav-button" onClick={prevImage}>
          <ArrowBack style={{ color: 'grey' }} />
        </Button>
        <Button className="nav-button" onClick={nextImage}>
          <ArrowForward style={{ color: 'grey' }} />
        </Button>
      </div>
    </div>
  );
};

export default Carousel;

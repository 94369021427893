// ResponsiveContainer.js
import React from 'react';
import './responsivecontainer.css';
import './moderntext'
import ModernText from './moderntext';

const ResponsiveContainer = ({ images }) => {
  return (
    <div className="responsive-container">
      {images.map((image, index) => (
        <div key={index} className="card">
          <img src={image.src} alt={image.alt} />
          <ModernText><p>{image.text}</p></ModernText>
        </div>
      ))}
    </div>
  );
};

export default ResponsiveContainer;


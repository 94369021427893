import React from 'react';
import './ModernText.css'; // Import the CSS file for styling

const ModernText = ({ children }) => {
  return (
    <div className="modern-text">
      {children}
    </div>
  );
};

export default ModernText;

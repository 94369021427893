import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ReactGA from 'react-ga';

import {
  createBrowserRouter,
  RouterProvider,
  useLocation
} from "react-router-dom";
import Second from './pages/secondpages';
import Contact from './pages/Contact';

// Initialize Google Analytics
ReactGA.initialize('G-Q5H27MC8BH');

// Custom hook to track page views
const usePageTracking = () => {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
};

// Wrapper component to use the tracking hook
const AppWithTracking = () => {
  usePageTracking();
  return <App />;
};

const router = createBrowserRouter([
  { path: "/", element: <AppWithTracking /> },
  { path: "Second", element: <Second /> },
  { path: "Contact", element: <Contact /> },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);




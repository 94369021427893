import React from 'react';
import Layout from '../components/layout';
import ContactForm from '../components/formcontact';

import ImageContainer from '../components/imagecontainer';



function Contact() {

  const customImages = [
    { src: '/whatsapp.png', alt: 'Custom Image 1', link: '//wa.me/+306940702320' },
    { src: '/Linkedin.png', alt: 'Custom Image 2', link: 'https://www.linkedin.com/company/aretedigital/about/' },
    { src: '/INSTAGRAM.png', alt: 'Custom Image 3', link: 'https://www.instagram.com/arete_soluciones_digitales/'}
  ];
  
  return (
    <Layout>
    <div>
      <h1>Contacto</h1>
      <ContactForm/>
     
      <ImageContainer images={customImages}/>
        
    </div>
    </Layout>
  );
};

export default Contact;
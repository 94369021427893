import React from 'react';
import './TextContainer.css'; // Import your CSS file for styling

const TextContainer = ({ children }) => {
  return (
    <div className="text-container">
      {children}
    </div>
  );
};

export default TextContainer;
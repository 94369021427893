import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import './formcontact.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://areteserver-production.up.railway.app/newinterested', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Reset form fields after successful submission
      setFormData({ name: '', email: '', message: '' });
      alert('¡Formulario enviado con éxito!');
      console.log(formData);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Ocurrió un error al enviar el formulario. Por favor, inténtelo de nuevo más tarde.');
    }
  };

  return (
    <Container maxWidth="md" className="contact-form-container">
      <Typography 
        variant={isMobile ? "h6" : "h4"} // Adjust variant based on screen size
        align="center" 
        gutterBottom
      >
        ¡Contactanos utilizando este formulario y obtené un 10% de descuento en todos nuestros servicios!
      </Typography>
      <form onSubmit={handleSubmit} className="contact-form">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Nombre"
              name="name"
              value={formData.name}
              onChange={handleChange}
              variant="outlined"
              className="contact-form-field"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Correo Electrónico"
              name="email"
              value={formData.email}
              onChange={handleChange}
              variant="outlined"
              className="contact-form-field"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Mensaje"
              name="message"
              value={formData.message}
              onChange={handleChange}
              variant="outlined"
              multiline
              rows={4}
              className="contact-form-field"
              required
            />
          </Grid>
        </Grid>
        <Button 
          type="submit" 
          variant="contained" 
          color="primary" 
          fullWidth 
          className="contact-form-submit"
          sx={{ marginTop: 2 }}
        >
          Enviar
        </Button>
      </form>
    </Container>
  );
};

export default ContactForm;

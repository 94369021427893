// ImageContainer.js
import React from 'react';
import './ImageContainer.css';

const ImageContainer = ({ images }) => {
  return (
    <div className="responsive-container-image">
      {images.map((image, index) => (
        <div key={index} className="card-image">
          <a href={image.link} target="_blank" rel="noopener noreferrer">
            <img src={image.src} alt={image.alt} />
          </a>
        </div>
      ))}
    </div>
  );
};

export default ImageContainer;